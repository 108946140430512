
import { Component, Vue,Watch } from "vue-property-decorator";
import { dingtalkService,UserService } from "src/services";
import { Toast } from "vant";
// import sensorsUtils from "src/utils/sensorsUtils"
import dd from "dingtalk-jsapi";



@Component
export default class DingTalk extends Vue
{
    /**
     * 登录数据模型
     * @private
     * @returns ILogin
     */
     private modelData =
    {
        code: "", // 企业编号
        simpleIntro:"", // 钉钉corpId
        authCode:"" // 钉钉授权码
    };


    /**
     * 当路由发生变化的时候操作。
     * @protected
     * @param {any} - to 当前路由实例。
     * @returns {void}
     */
     protected async created(to: any)
    {
        try
        {
            this.modelData.code = localStorage.getItem("appid");
            // 获取机构信息
            await this.getOrgInfo(this.modelData.code);
            this.modelData.simpleIntro = JSON.parse(localStorage.getItem("orgInfo")).simpleIntro;
            this.onLogin();
            
        }
        catch(err)
        {
            console.log(err)
        }
     }

    /**
     * 获取机构详情
     * @private
     * @returns void
     */
     private async getOrgInfo(code: string): Promise<void>
    {
        try
        {
            let {content: result} =  await UserService.instance.getOrgInfo(code);
            if(result.data)
            {
                this.$store.dispatch("setOrgInfo", result.data);
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }


    /**
     * 登录
     * @private
     * @returns void
     */
     private async onLogin(): Promise<void> {
        try
        {
            let that = this
            dd.getAuthCode({
                corpId: this.modelData.simpleIntro,
                success: async (res) => {
                    const { code } = res;
                    that.modelData.authCode = code

                    let {content: result} =  await dingtalkService.instance.dingTalkLogin({
                        code:that.modelData.code,
                        authCode:that.modelData.authCode
                    });

                    if(result.data)
                    {
                        // 绑定神策用户数据
                        // sensorsUtils.bind("identity_Id",result.data.userId +"");      
                        that.$store.dispatch("setUserInfo", result.data);
                        that.$nextTick(()=>
                        {
                            that.$router.replace({name: "home"});
                        })
                    }
                },
                fail: () => {},
                complete: () => {},
            });


        }
        catch(err)
        {
            Toast(err);
            
        }
     }


}



